<template>
    <footer class="footer px-4">
      <b-row align-v="center" class="justify-content-lg-between">
        <b-col lg="6">
          <div class="copyright text-center text-lg-left text-muted">
          <img src="../../assets/img/footer-logo.png"> <br/><br/>
            © {{year}} FINCORE <br/>Promotora de Negocios Vilna S.A.P.I. de C.V. <br/>Avenida Paseo Royal Country #4650 Int 401-E <br/>
            Puerta de Hierro, Zapopan, Jalisco <br/>CP. 45400, México.
          </div>
        </b-col>
      </b-row>
    </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
</style>