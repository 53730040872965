import DashboardLayout from '@/views/Layout/DashboardLayout.vue';

import NotFound from '@/views/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    //redirect: 'dashboard',
    component: DashboardLayout,
    children: [
      {
        path: '/',
        name: 'calculadora',
        component: () => import( '../views/Dashboard.vue'),
      },
      { path: '*', component: NotFound }
    ]
  }
];

export default routes;
