import Vue from 'vue'
import Vuex from 'vuex'
import jwt_decode from 'jwt-decode'
import router from '../routes/router'



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    usuario: '',
    customers: [],
    customer: [],
    investments: [],
    investment:[],
    oportunities: [],
    oportunity:[],
    texto: ''
  },
  mutations: {
    obtenerUsuario(state, payload){
      state.token = payload
      if(payload === ''){
        state.usuario = ''
      }else{
        state.usuario = jwt_decode(payload)
        // console.log(state.usuario);
        router.push({name: 'calculadora'})
      }
    },
    setCustomers(state, payload){
      state.customers = payload
    },
    setCustomer(state, payload){
      state.customer = payload
    },
    setInvestments(state, payload){
      state.investments = payload
    },
    setInvestment(state, payload){
      state.investment = payload
    },
    setOportunities(state, payload){
      state.oportunities = payload
    },
    setOportunity(state, payload){
      state.oportunity = payload
    }
  },
  actions: {
    guardarUsuario({commit}, payload){
      localStorage.setItem('token', payload)
      commit('obtenerUsuario', payload)
    },
    cerrarSesion({commit}){
      commit('obtenerUsuario', '')
      localStorage.removeItem('token')
      router.push({name: 'login'})
    },
    leerToken({commit}){
      const token = localStorage.getItem('token')
      if(token){
        commit('obtenerUsuario', token)
      }else{
        commit('obtenerUsuario', '')
      }
    },
    buscador({state}, payload){
      state.texto = payload.toLowerCase()
    },
    getCustomers({commit}, customers){
      commit('setCustomers', customers)
    },
    getCustomer({commit}, customer){
      commit('setCustomer', customer)
    },
    getInvestments({commit}, investments){
      commit('setInvestments', investments)
    },
    getInvestment({commit}, investment){
      commit('setInvestment', investment)
    },
    getOportunities({commit}, oportunities){
      commit('setOportunities', oportunities)
    },
    getOportunity({commit}, oportunity){
      commit('setOportunity', oportunity)
    },
    eliminarInvestment({state}, item){
      let a =state.investments.indexOf(item)
      state.investments.splice( a, 1 )
    },
    activarInvestment({state}, item){
      let a =state.investments.indexOf(item)
      state.investments[a].status.status = 'ACTIVO'

    },
  },
  getters:{
    estaActivo: state => !!state.token,
    filteredCustomer(state){
      let arregloFiltrado = []
      for(let customer of state.customers){
        let valor = customer.rfc
        // console.log(valor);
        if(valor.toString().toLowerCase().indexOf(state.texto) >= 0){
          arregloFiltrado.push(customer)
        }
      }
      return arregloFiltrado.reverse()
    },
    filteredInvestment(state){
      let arregloFiltrado = []
      for(let investment of state.investments){
        // console.log(state.investments);
        let valor = investment.customer.business_name + investment.customer.name
        if(valor.toString().toLowerCase().indexOf(state.texto) >= 0){
          switch (investment.customer.customertypes.type_description){
            case "PF":
              investment.namecomplete = investment.customer.name + " " + investment.customer.last_name + " " + investment.customer.m_last_name
              break;
            case "PM":
              investment.namecomplete = investment.customer.business_name
              break;
          }
          arregloFiltrado.push(investment)
        }
      }
      // console.log(arregloFiltrado);
      return arregloFiltrado.reverse()
    },
  },
  modules: {
  }
})
